import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ProjectsSection from "../sections/ProjectsSection"

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects"
      description="Personal projects are a great way to learn new technology and methods to solve problems. Come see some of the things I've tried out."
      lang="EN"
    />

    <ProjectsSection />

  </Layout>
)

export default ProjectsPage
