import React from 'react'
import styled from 'styled-components'

import Img from "gatsby-image"

// Sizes for media queries
import { device } from '../../device-sizes'

const ProjectTileContainer = styled.div`
    width: 100%; 
    background-color: #ffffff;

    @media ${device.mobileS} {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "Image"
        "Copy";
        grid-gap: 20px;
    }

    @media ${device.tablet} {
        display: flex;
        grid-gap: 0px;
        flex-direction: row;
    }
`

const Tags = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0px; 
    padding: 0px;
    margin-top: 20px;
    font-family: Roboto, sans-serif;
    font-weight: 400;

    & li {
        color: #302e43;
    }

    & li:last-child {
        margin-right: 0px;
    }

    @media ${device.mobileS} {
        margin-top: 5px;

        & li {
            margin-right: 20px;
            font-size: 15px;
        }
    }

    @media ${device.laptop} {
        margin-top: 20px;

        & li {
            margin-right: 25px;
            font-size: 18px;
        }
    }

    @media ${device.laptopL} {
        & li {
            margin-right: 25px;
            font-size: 20px;
        }
    }
`

const Title = styled.h2`
    font-weight: 700;
    font-family: Poppins, sans-serif;
    margin: 0px;
    color: #302e43;

    @media ${device.mobileS} {
        font-size: 22px;
    }

    @media ${device.mobileL} {
        font-size: 26px;
    }

    @media ${device.tablet} {
        font-size: 22px;
    }

    @media ${device.laptop} {
        font-size: 32px;
    }

    @media ${device.laptopL} {
        font-size: 40px;
    }
`

const Copy = styled.p`
    font-family: Roboto, san-serif;
    color: #6E6D7A;
    line-height: 1.4;

    @media ${device.mobileS} {
        font-size: 17px;
        margin: 10px 0px 0px 0px;
        font-weight: 400;
    }

    @media ${device.tablet} {
        font-size: 18px;
    }

    @media ${device.laptop} {
        font-size: 20px;
        font-weight: 700;
        margin: 15px 0px 0px 0px;
    }

    @media ${device.laptopL} {
        font-size: 22px;
        margin: 30px 0px 0px 0px;
    }

    @media ${device.desktop} {
        font-size: 24px;
        margin: 40px 0px 0px 0px;
    }
`

export const ProjectTileLeft = ({image, link, tags, title, copy}) => {

    const ProjectImage = styled.a`
        background-color: #f2f2f2;
        height: auto;
        grid-area: Image;

        @media ${device.mobileS} {
            width: 100%;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
            border-radius: 10px;

            & img {
                border-radius: 10px;
            }
        }

        @media ${device.tablet} {
            width: 45%;
            transition: 0.3s;

            & img {
                border-radius: 0px 10px 10px 0px;
            }

            &:hover{
                transform: scale(1.05);
                box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.9);
            }
        }

        @media ${device.desktop} {
            width: 40%;
        }
    `

    const ProjectCopyContainer = styled.div`
        display: flex;
        flex-direction: column;
        grid-area: Copy;
        
        @media ${device.tablet} {
            padding-left: 50px;
            padding-right: 35px;
            width: 55%;
        }

        @media ${device.laptop} {
            padding-right: 50px;
            padding-left: 75px;
        }

        @media ${device.laptopL} {
            padding-left: 100px;
            padding-right: 75px;
        }

        @media ${device.desktop} {
            width: 60%;
            padding-left: 165px;
            padding-right: 145px;
        }
    `
    
    const tagsElm = tags.map((tag) => (
        <li key={tag}>{tag}</li>
    ))

    return (
        <ProjectTileContainer>
            <ProjectImage href={link}>
                <Img fluid={image} />
            </ProjectImage>
            <ProjectCopyContainer>
            <Title color="#302e43">{title}</Title>
                <Tags>
                    {tagsElm}
                </Tags>
                <Copy color="#6E6D7A">
                    {copy}
                </Copy>
            </ProjectCopyContainer>
        </ProjectTileContainer>
    )
}

export const ProjectTileRight = ({image, link, tags, title, copy}) => {

    const ProjectImage = styled.a`
        background-color: #f2f2f2;
        height: auto;
        grid-area: Image;

        @media ${device.mobileS} {
            width: 100%;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
            border-radius: 10px;

            & img {
                border-radius: 10px;
            }
        }

        @media ${device.tablet} {
            width: 45%;
            border-radius: 10px 0px 0px 10px;
            transition: 0.3s;

            & img {
                border-radius: 10px 0px 0px 10px;
            }

            &:hover{
                transform: scale(1.05);
                box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.9);
            }     
        }

        @media ${device.desktop} {
            width: 40%;
        }
    `

    const ProjectCopyContainer = styled.div`
        display: flex;
        flex-direction: column;
        grid-area: Copy;

        @media ${device.mobileS} {
            width: 100%;
        }

        @media ${device.tablet} {
            padding-right: 50px;
            padding-left: 35px;
            width: 55%;
        }

        @media ${device.laptop} {
            padding-right: 75px;
            padding-left: 50px;
        }

        @media ${device.laptopL} {
            padding-right: 100px;
            padding-left: 75px;
        }

        @media ${device.desktop} {
            width: 60%;
            padding-right: 165px;
            padding-left: 140px;
        }
    `

    const tagsElm = tags.map((tag) => (
        <li key={tag}>{tag}</li>
    ))

    return (
        <ProjectTileContainer>
            <ProjectCopyContainer>
                <Title color="#302e43">{title}</Title>
                <Tags>
                    {tagsElm}
                </Tags>
                <Copy color="#6E6D7A">
                    {copy}
                </Copy>
            </ProjectCopyContainer>
            <ProjectImage href={link}>
                <Img fluid={image} />
            </ProjectImage>
        </ProjectTileContainer>
    )

}