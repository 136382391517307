import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"

// Sizes for media queries
import { device } from '../device-sizes'

import { ProjectTileLeft, ProjectTileRight } from '../components/ProjectsPage/ProjectTile'

const ProjectsSection = () => {

    const data = useStaticQuery(graphql`
    query ProjectQuery {
        projectImage1: file(relativePath: { eq: "project-turnip1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
            }
          }
        }
        projectImage2: file(relativePath: { eq: "wildlife-reserve1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
              }
            }
          }
        projectImage3: file(relativePath: { eq: "colorcomb.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
              }
            }
        }
        projectImage4: file(relativePath: { eq: "cardaddy1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    
    //const Section =
    
    const SectionTitle = styled.h1`
        font-family: Poppins, sans-serif;
        margin: 0 auto;
        color: #302e43;

        @media ${device.mobileS} {
            font-size: 35px;
            border-bottom: 4px solid #302e43;
            padding: 0px 20px 10px 20px;
        }

        @media ${device.mobileL} {
            font-size: 38px;
        }

        @media ${device.tablet} {
            font-size: 50px;
            border-bottom: 6px solid #302e43;
            padding: 0px 20px 10px 20px;
        }

        @media ${device.laptop} {
            font-size: 60px;
            border-bottom: 8px solid #302e43;
            padding: 0px 20px 10px 20px;
        }

        @media ${device.laptopL} {
            font-size: 70px;
            padding: 0px 20px 10px 20px;
        }

        @media ${device.desktop} {
            font-size: 80px;
            border-bottom: 10px solid #302e43;
            padding: 0px 20px 20px 20px;
        }
    `

    const TileSection = styled.div`
        overflow: hidden;
        display: grid;
        grid-gap: 40px;
        background-color: #ffffff;


        @media ${device.mobileS} {
            grid-gap: 40px;
            grid-template-columns: 100%;
            padding: 50px 25px;
        }

        @media ${device.tablet} {
            grid-gap: 140px;
            padding: 50px 0px;
        }

        @media ${device.laptop} {
            grid-gap: 180px;
            padding: 90px 0px;
        }

        @media ${device.laptopL} {
            grid-gap: 220px;
            padding: 120px 0px;
        }

        @media ${device.desktop} {
            grid-gap: 300px;
            padding: 150px 0px;
        }
    `

    return (
        <TileSection>
            <SectionTitle>Projects</SectionTitle>

            <ProjectTileLeft 
                image={data.projectImage4.childImageSharp.fluid} 
                link="/projects/car-daddy"
                tags={['Vehicle Maintenance', 'iOS', 'Microservices']}
                title="CarDaddy - In Development"
                copy="Find out how to maintain your car throughout its life span. Get the checkup information and solution for your specific make and model."
            />
            <ProjectTileRight 
                image={data.projectImage1.childImageSharp.fluid} 
                link="/projects/project-turnip"
                tags={['Desktop', "NodeJS", "Electron"]}
                title="Project Turnip"
                copy="A match event tracker for Smash Bros. Melee, which displays combo strings and final results. Built on top of the open-source Slippi SDK."
            />
            <ProjectTileLeft 
                image={data.projectImage2.childImageSharp.fluid} 
                link="/projects/wildlife-reserve"
                tags={['C#', "ASP.NET", "CMS"]}
                title="Wildlife Reserve CMS"
                copy="A CMS that helps display information about different creatures inside wildlife reserves."
            />

            <ProjectTileRight 
                image={data.projectImage3.childImageSharp.fluid} 
                link="/projects/colorcomb"
                tags={['PHP', "Color Palettes", "MySQL"]}
                title="ColorComb"
                copy="Create palettes and get different shades or find color palettes with ColorComb. It's a web app built with PHP and MySQL."
            />
        </TileSection>
    )

}
export default ProjectsSection